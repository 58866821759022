@import '../../assets/colors.scss';
.navbar {
  /* background-color: $dark_blue;
  background: rgb(26,36,57); */
  background: linear-gradient(
    90deg,
    rgba(26, 36, 57, 1) 0%,
    rgba(67, 67, 130, 1) 49%,
    rgba(26, 36, 57, 1) 100%
  );
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 0 0.3rem;

  .menu-wrapper {
    max-width: 75rem;
    //border: 1px solid $honeydew;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  i {
    color: $honeydew;
    font-size: 2rem;
    padding: 0 1rem;
    cursor: pointer;
    &:hover {
      scale: 1.03;
      -webkit-font-smoothing: antialiased;
    }
    //border: 1px solid $honeydew;
  }

  .navbar-btn {
    text-decoration: none;
    color: $honeydew;
    //border: 0.08rem solid $honeydew;
    padding: 0.5rem;
    border-radius: 0.3rem;

    text-transform: uppercase;
    font-size: larger;
    transition: 0.3s;

    &:hover {
      scale: 1.03;
      cursor: pointer;
    }
  }
  .login-out {
    background-color: $honeydew;
    color: rgba(26, 36, 57, 1);
    font-weight: 500;
  }

  .nav-left-side {
    display: flex;
  }

  .nav-right-side {
    //border: 1px solid $honeydew;
    display: flex;
    column-gap: 1rem;
  }
}
