@import '../../assets/colors.scss';

.single-post-wrapper {
  //background-color: #f3f4f6;
  font-size: 17px;
  color: #1c1e21;
  line-height: 28px;
  letter-spacing: 0.05rem;
  //text-align: justify;

  .content-wrapper {
    //border: 0.1rem solid $honeydew;
    background-color: white;
    max-width: 50rem;
    margin: 2rem auto;
    padding: 1rem;

    .date-and-button-wrapper {
      display: flex;
      justify-content: space-between;

      .button-wrapper {
        display: flex;
        gap: 0.5rem;
      }
    }

    .date {
      color: #aaa;
      font-style: italic;
    }

    .terminal-output {
      background-color: #333;
      border-radius: 0.3rem;
      color: #fff;
      padding: 0 1rem;
      font-family: 'Courier New', Courier, monospace;
    }

    code {
      font-family: monospace;
      //background-color: $purple;
    }

    .inline-code {
      background-color: $honeydew;
      border: 0.07rem solid $ocean_green;
      border-radius: 0.5rem;
      padding: 0 0.2rem;
      word-break: keep-all;
    }

    .ql-syntax {
      overflow: auto;
    }
    img {
      width: 100%;
      //aspect-ratio: 16/9;
      object-fit: contain;
    }

    .post-title {
      font-size: 3rem;
      padding: 1rem 0;
      text-align: left;
      font-weight: 600;
      //border: 1px solid $honeydew;
      line-height: 3.5rem;
    }

    blockquote {
      border-left: 0.4rem solid #434382;
      padding: 1rem;
      font-style: italic;
      margin-inline-start: 0;
      margin-inline-end: 0;
      background-color: $background_color;
    }
  }
}
