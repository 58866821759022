@import '../../assets/colors.scss';

.posts-container {
  //border: 1px solid $ocean_green;
  max-width: 75rem;

  margin: 2rem auto;
  padding: 0 0.3rem;

  display: grid;
  --auto-grid-min-size: 15rem;
  //justify-content: center;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 2rem;
}
