@import '../../assets/colors.scss';

.dark-background {
  background-color: rgba($dark_blue, 0.3);
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
}

.modal {
  min-width: 20rem;
  min-height: 10rem;
  background-color: $background_color;
  color: $dark_blue;
  z-index: 10;
  border-radius: 1rem;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modal-header {
  height: 3rem;
  background-color: $background_color;
  overflow: hidden;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.heading {
  margin: 0;
  padding: 0.8rem;
  color: $dark_blue;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.modal-content {
  padding: 1rem;
  font-size: 1rem;
  columns: $dark_blue;
  text-align: center;
}

.modal-actions {
  position: absolute;
  bottom: 0.2rem;
  margin-bottom: 0.8rem;
  width: 100%;
}

.actions-container {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
}

.close-btn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 1.2rem;
  color: $dark_blue;
  background: transparent;
  transition: all 0.25s ease;
  //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.close-btn:hover {
  //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(0.2rem, -0.2rem);
}
