.cpp {
  color: red;
}
.javascript {
  color: darkorange;
}
.html {
  color: blue;
}
.css {
  color: violet;
}

.black {
  color: #444;
}
.computer {
  color: darkgreen;
}
.react {
  color: darkblue;
}
