.spinner-wrapper {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 15rem);
  display: flex;
  justify-content: center;
  align-items: center;
  //border: 1px solid black;
}

.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #434382 94%, #0000) top/9px 9px
      no-repeat,
    conic-gradient(#0000 30%, #434382);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}
