@import '../../assets/colors.scss';

.form-group {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  label {
    text-align: left;
    padding: 0.5rem 0;
  }
  input,
  textarea {
    border: 0.1rem solid $honeydew;
    outline: none;
    padding: 0.5rem;
    font-size: 1rem;
    font-family: monospace;
    &:focus {
      border: 0.1rem solid $ocean_green;
    }
  }

  select {
    padding: 0.5rem;
    border: 0.1rem solid $honeydew;
  }
  select option {
    padding: 0.5rem;
  }
}

.submit-cancel-btns {
  display: flex;
  gap: 1rem;
  text-transform: uppercase;
}

.submit-btn {
  width: 50%;
  background-color: $purple;
  border: none;
  color: $honeydew;
  font-weight: 500;
  letter-spacing: 0.15rem;
  font-size: 1rem;
  height: 3rem;
  text-transform: uppercase;

  border-bottom-left-radius: 0.5rem;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}
.cancel {
  max-width: 50%;
  border-radius: 0;
  background-color: $honeydew;
  border-bottom-right-radius: 0.5rem;
  border: 0.1rem solid $dark_blue;
  color: $dark_blue;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.15rem;
  background-color: transparent;
  text-transform: uppercase;
}

.ql-editor {
  min-height: 20rem;
}
