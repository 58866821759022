@import '../../assets/colors.scss';

.login-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-wrapper {
    max-width: 20rem;
  }

  img {
    padding: 2rem 5rem;
    object-fit: contain;
    width: 100%;
  }

  input {
    width: 100%;
    padding: 1rem;
    margin: 1rem 0;
  }
}
