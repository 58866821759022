@import '../../../assets/colors.scss';

.menus {
  //border: 1px solid black;
  width: min-content;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding-inline-start: 0;
  .link {
    text-decoration: none;
    color: $honeydew;
    //border: 0.08rem solid $honeydew;
    padding: 0.5rem;
    border-radius: 0.3rem;

    text-transform: uppercase;
    font-size: larger;
    transition: 0.3s;

    &:hover {
      scale: 1.03;
    }
  }
}
