@import '../../assets/colors.scss';

.section-contact {
  max-width: 75rem;
  margin: auto;
  margin-bottom: 5rem;
  width: 100%;
  //height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .contact-heading {
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    //color: $honeydew;
    color: #ddd;
    //border: 1px solid $dark_blue;
    //text-shadow: 2rem 2rem 4rem $honeydew;
  }

  .form-container {
    width: 100%;
    //height: 50%;

    //background-color: $honeydew;
    border-radius: 0.5rem;
    //box-shadow: 0.3rem 0.3rem 0.4rem #ccc /* , -0.1rem -0.1rem 0.4rem #ccc */;

    display: flex;
    align-items: flex-start;
    justify-content: space-around;

    .img-wrapper {
      width: 40%;
      //border-right: 1px solid #ddd;
      //margin-top: 2rem;
      //border: 0.1rem solid $dark_blue;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .profile-name {
        color: $dark_blue;
        padding: 1rem;
        font-size: 1.1rem;
        text-align: center;
      }
    }
    .profile-img {
      //aspect-ratio: 4/3;
      max-width: 10rem;
      border-radius: 50%;
      object-fit: contain;
    }

    .contact-form {
      //border: 0.1rem solid $dark_blue;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      padding: 3rem;
      //margin: 2rem;
      border-radius: 0.5rem;
      width: 100%;
      box-shadow: 0.3rem 0.3rem 0.4rem #ccc, -0.1rem -0.1rem 0.4rem #ccc;

      input,
      textarea {
        margin: 1rem;
        padding: 1rem 2rem;
        outline: none;
        border-radius: 0.5rem;
        letter-spacing: 0.1rem;
        font-size: 1rem;
        border: 0.1rem solid $honeydew;
        /* box-shadow: inset 0.1rem 0.1rem 0.1rem #ddd,
          inset -0.1rem -0.1rem 0.1rem #ddd; */

        &:focus {
          border: 0.1rem solid $ocean_green;
        }
      }

      textarea {
        min-height: 10rem;
      }
    }
  }
}

@media only screen and (max-width: 760px) {
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .img-wrapper {
      margin: auto;
    }
  }
}
