@import '../../assets/colors.scss';

.footer-container {
  height: 5rem;
  /* background-color: $dark_blue; */
  background: rgb(26, 36, 57);
  background: linear-gradient(
    90deg,
    rgba(26, 36, 57, 1) 0%,
    rgba(67, 67, 130, 1) 49%,
    rgba(26, 36, 57, 1) 100%
  );
  color: $honeydew;
  text-align: center;
  padding-top: 2rem;
}
