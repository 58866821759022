@import '../../assets/colors.scss';

.navlink {
  text-decoration: none;
  color: $dark_jungle_green;
}

.card {
  display: flex;
  background-color: white;
  flex-direction: column;
  //justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba($dark_jungle_green, $alpha: 0.2);
  //background-color: $honeydew;
  //max-width: 20rem;
  border-radius: 0.5rem;
  text-align: center;
  height: 100%;
  transition: 0.3s;

  h2 {
    padding: 0.5rem;
    //border: 1px solid black;
    color: $dark_jungle_green;
    margin-block-end: 0;
    margin-block-start: 0;
  }
  img {
    aspect-ratio: 16/9;
    object-fit: contain;
    padding: 1rem 0.5rem 0 0.5rem;
  }

  .body {
    //border: 1px solid $dark_jungle_green;
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-style: italic;
    color: darkgrey;
    vertical-align: top;
    margin-block-start: 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .tags {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 0.5rem 1rem 0.5rem;
  }
  &:hover {
    scale: 1.05;
  }
}

button {
  border: none;
  color: $honeydew;
  font-weight: 500;
  letter-spacing: 0.05rem;
  background-color: $dark_jungle_green;
  outline: 0;
  width: 100%;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-size: 1rem;
  padding: 0.7rem;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
