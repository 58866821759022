@import '../../assets/colors.scss';

.button {
  border-radius: 0.3rem;
  background-color: $purple;
  color: $honeydew;
  //height: 2rem;
  padding: 0.25rem 1rem;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}
