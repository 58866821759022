@import '../../assets/colors.scss';

.create-post-wrapper {
  //border: 0.1rem solid $honeydew;
  max-width: 50rem;
  margin: auto;
  margin-bottom: 3rem;
  padding: 0 0.3rem;

  color: $dark_jungle_green;
}
