@import '../../assets/colors.scss';

.ql-insertInlineCode,
.ql-terminal-output {
  color: #000;
}

#toolbar {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: $background_color;
  z-index: 5;
}
